<template>
	<div class="lead mb-5">
		{{ $t("contact.message-was-send") }}
	</div>
</template>

<script>
export default {
	name: "Email-success",
	data() {
		return {};
	},
};
</script>

<style lang="css" scoped></style>
